.login_container{
    height: 100vh;
    /* background-color: aliceblue; */
    background: linear-gradient(to top, #ffff 50%, #0000ff 50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_form{
    border: #afb7bf61 solid;
    display: flex;
    gap: 30px;
    flex-direction: column;
    background: #ffff;
    height: 52%;
    width: 33%;
    justify-content: space-around;
    padding: 22px;
}

.user_input_field{
    width: 100%;
    height: 33px;
    border-radius: 12px;
    border: 2px solid grey;
}

.login_btn{
    height: 50px;
    color: white;
    background-color: #186ced;
    border-radius: 12px;
    tab-size: px;
    text-size-adjust: 22;
}