.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}


.home_container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 0.2fr 1.5fr 0.8fr;
  grid-template-areas:
    "nav nav nav nav"
    "sidebar main main main"
    "footer footer footer footer";
}


.footer {
  height: 50px;
  color: #f7f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  grid-area: footer;
}

aside {
  /* padding: 5px; */
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr minmax(150px, 87%);
  height: 100vh;
  ;
}



.drawer {
  background: #d8dcd6;
  width: 0px;
  transition: width 0.4s;
  position: relative;
  left: -120px;


}

.opened {
  min-width: 100%;
  left: 0px;
}

.drawer_1 {
  background: #d8dcd6;
  width: 0px;
  transition: left 0.4s ease-in;
  position: relative;
  left: -120px;
  /* transition-timing-function:ease-in */

}

.opened_1 {
  width: 100%;
  left: 0px;
}

.main {
  flex: 1;
  background: #f7f5f5;
  width: 100%;
  grid-area: main;
}

.main_section {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
}

/* .sidebar{
  background-color: rgb(55 65 81) !important;
} */
aside>div {
  background-color: #E1E4E9 !important;
}

.spinner-container {
  height: 100vh;
  background: transparent;
  /* align-content: center; */
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: currentcolor;
}




.date-range-container {
  position: relative;

  input {
    height: 56px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    font-size: 16px;
  }

  label {
    position: absolute;
    z-index: 1;
    background-color: #ffff;
    font-size: 11px;
    left: 12px;
    padding: 2px 4px;
    top: -9px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  >button {
    background-color: #00AAE3 !important;
    min-height: 40px;
    min-width: 112px;
    color: #ffff;

  }

  >button:disabled {
    background-color: #D3D3D3 !important;
    min-height: 40px;
    min-width: 112px;

  }
}

.download_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  >button {
    background-color: #00AAE3 !important;
    min-height: 00px;
    min-width: 0px;
    color: #ffff;

  }

  >button:disabled {
    background-color: #D3D3D3 !important;
    min-height: 0px;
    min-width: 0px;

  }
}


.checkBoxIcon {
  >span {
    padding: 2px !important;
  }
}

.mm-sidebar {
  .MuiListItemIcon-root {
    min-width: 35px;
  }

  .active * {
    color: #00AAE3
  }
}

.tab-panel>div {
  padding-left: 0;
  padding-right: 0;
}

.forgetPassword_container {
  height: 100vh;
  background-color: #E1E4E9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paper-container {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}

.resetPassword_button {
  margin-top: 23px;
  display: flex;
  justify-content: flex-start;

  >button {
    background-color: #00AAE3 !important;
    min-height: 40px;
    min-width: 112px;
    padding: auto;
    margin: 3px;

  }

  >button:disabled {
    background-color: #D3D3D3 !important;
    min-height: 40px;
    min-width: 112px;

  }
}


.password-text-input{
  input:focus{
    box-shadow: none;
  }
}


.refresh-button {
  >button {
    background-color: #00AAE3 !important;
    min-height: 40px;
    min-width: 112px;
    padding: 2px;
    margin-left: 4px;
    margin-top: 11px;

    color: #ffff
  }
}

.predefined-report-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

}


.report_type_container {
  padding: 20px;
  min-height: 15vh;
  background-color: #E1E4E9 !important;
}


.report_table_container {
  padding: 20px;
   min-height: 100%;
  background-color: #E1E4E9 !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.error-message{
  color: #CA3433;
    padding: 5px;
    font-size: small;
}
// @media screen and (min-width: 768px) {
//   canvas {
//     width: 50%;
//     height: 50%;
//   }
// }



// canvas {
//   width: 100%;
//   height: 100%;
//   font-size: 12px;
// }

/* Desktop Styles */
// @media screen and (min-width: 768px) {
//   canvas {
//     width: 50%;
//     height: 50%;
//     font-size: 16px;
//   }
// }

.chart-box {
  height: 480px;
}

@media only screen and (max-width: 600px) {
  .chart-box {
    width: 550px;
    height: 600px;
    font-size: 16px;
  }
  .table-box-reports {
    width: 550px;
    height: auto;
    font-size: 16px;
  }
  .btn_container{
    width: 550px;
    height: auto;
    font-size: 16px;
  }
  .raw_status_table_container{
    width: 550px;
    height: auto;
    font-size: 16px;
  }
  
}

@media only screen and (max-width: 300px) {
  .chart-box {
    width: 250px;
    height: 300px;
    font-size: 16px;
  }
  .table-box-reports {
    width: 250px;
    height: auto;
    font-size: 16px;
  }
  .btn_container{
    width: 250px;
    height: auto;
    font-size: 16px;
  }
  .raw_status_table_container{
    width: 350px;
    height: auto;
    font-size: 16px;
  }
}



@media only screen and (max-width: 400px) {
  .chart-box {
    width: 350px;
    height: 300px;
    font-size: 16px;
  }
  .table-box-reports {
    width: 350px;
    height: auto;
    font-size: 16px;
  }
  .btn_container{
    width: 350px;
    height: auto;
    font-size: 16px;
  }
.raw_status_table_container{
  width: 350px;
  height: auto;
  font-size: 16px;
}
  
}