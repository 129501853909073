.pagination{
    margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0 5rem;
}

.pagination>li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  .pagination>li.previous a,
  .pagination>li.next a,
  .pagination>li.break a {
    border-color: transparent;
  }
  .pagination>li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  .pagination>li.disabled a {
    color: grey;
  }
  .pagination>li.disable,
  .pagination>li.disabled a {
    cursor: default;
  }